import React, { useContext } from 'react'
import styled from 'styled-components'
import { colors } from '../../theme'
import SalesPictureTitle from '../../components/SalesPictureTitle'
import { union } from 'lodash'
import { useRedirect } from '../../hooks/useRedirect'
import { navigate } from '../../components/Link'
import { Button, Pulse } from '@monbanquet/crumble'
import { EventContext } from '../../components/context/EventContext'
import { AuthenticationContext } from '../../components/context/AuthenticationContext'
import { defaultConstraints } from '../../components/context/EventContextDefaults'
import CheckboxTile, { StyledCheckboxTile } from '../../components/CheckboxTile'
import { useWithDelay } from '../../hooks/useWithDelay'
import DemandeLayout from '../../components/DemandeLayout'
import { computeNextPageWithBaseUri } from '../../util/compute-next-page'

const ConstraintPage = ({ location: { state } }) => {
  const redirect = useRedirect(!state || !state.next, '/demande/format')
  const { user } = useContext(AuthenticationContext)

  const {
    state: { constraints, notes, multiMoment },
    dispatch: dispatchEventCtx,
  } = useContext(EventContext)

  const submit = () => {
    const nextUrl = computeNextPageWithBaseUri('demande', 'regime', {
      isUserLoggedIn: !!user,
      isMultiMoments: multiMoment,
    })

    navigate(nextUrl, { state: { next: true } })
  }
  const [submitWithDelay, isDelayed] = useWithDelay(submit, 400)

  return (
    <StyledConstraintPage>
      <DemandeLayout>
        {redirect ? null : (
          <div>
            <SalesPictureTitle
              title={`Le menu doit-il respecter un régime spécifique\u00A0?`}
            />
            <form
              onSubmit={e => {
                e.preventDefault()
                submitWithDelay()
              }}
            >
              <fieldset className="constraints">
                <ul>
                  {defaultConstraints.map(c => (
                    <CheckboxTile
                      key={c.value}
                      type="checkbox"
                      name="constraint"
                      value={c.value}
                      label={c.label}
                      checked={constraints.indexOf(c.value) !== -1}
                      small
                      onChange={e =>
                        dispatchEventCtx({
                          type: 'UPDATE_CONSTRAINTS',
                          payload: {
                            constraints: groupChecked(e, constraints),
                          },
                        })
                      }
                    />
                  ))}
                </ul>
              </fieldset>

              <label className="notes">
                <textarea
                  id="notes"
                  name="notes"
                  placeholder="Autres (allergies, intolérances...), indiquez ici vos besoins spécifiques..."
                  value={notes}
                  rows={1}
                  onChange={e =>
                    dispatchEventCtx({
                      type: 'UPDATE',
                      payload: {
                        notes: e.target.value,
                      },
                    })
                  }
                />
              </label>

              <div className="btn-bar">
                <Button id="previous" onClick={() => navigate(-1)}>
                  <div>&rarr;</div>
                  <span>&nbsp;Précédent</span>
                </Button>
                <Button id="next" type="submit">
                  {isDelayed ? (
                    <Pulse />
                  ) : (
                    <span>{`${
                      constraints.length === 0 ? `Passer` : `Suivant`
                    } →`}</span>
                  )}
                </Button>
              </div>
            </form>
          </div>
        )}
      </DemandeLayout>
    </StyledConstraintPage>
  )
}

function groupChecked(e, arr) {
  if (!e.target.checked) {
    return arr.filter(q2 => q2 !== e.target.value)
  } else {
    return union(arr, [e.target.value])
  }
}

const StyledConstraintPage = styled.div`
  .page-content {
    max-width: 550px;
  }

  form {
    text-align: left;
    margin: 0 auto;
    margin-top: 30px;
  }

  fieldset {
    border: none;
    margin-bottom: 30px;
  }

  .constraints > ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    > ${StyledCheckboxTile} {
      margin: 5px;
      label {
        font-size: 1.1rem;
      }
    }
  }

  .notes {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    font-weight: 500;
    color: ${colors.text.blue};

    textarea {
      border: none;
      background-color: white;
      border-radius: 8px;
      font-size: 16px;
      height: 130px;
      padding: 22px;
      font-weight: normal;
      color: ${colors.brand.black};
      outline: none;

      ::placeholder {
        color: #383838;
      }
      :-ms-input-placeholder {
        color: #383838;
      }
      ::-webkit-input-placeholder {
        color: #383838;
      }
    }
  }
`

export default ConstraintPage
export { StyledConstraintPage }
