import React from 'react'
import styled from 'styled-components'
import { colors } from '../theme'
import Check from '../assets/new-home/check.svg'

const CheckboxTile = ({
  type = 'checkbox',
  name,
  checked,
  onChange,
  label,
  icon,
  value,
  small = false,
  ...props
}) => (
  <StyledCheckboxTile className={small ? 'small' : undefined} {...props}>
    <div className={checked ? 'checked' : undefined}>
      {icon && <img src={icon} alt={label} />}
      <span className="label">{label}</span>
      <input
        className="tile"
        type={type}
        checked={checked}
        onChange={onChange}
        name={name}
        id={value}
        value={value}
      />
      <label htmlFor={value}>
        <div className={checked ? 'checked' : undefined}>
          {checked && <img src={Check} alt="check" />}
        </div>
      </label>
    </div>
  </StyledCheckboxTile>
)

const StyledCheckboxTile = styled.li`
  &,
  label {
    display: flex;
    align-items: center;
    text-align: center;
  }

  &:hover {
    box-shadow: 1px 1px 6px #00000029;
  }

  min-width: 100px;
  height: 200px;
  background-color: white;
  border: none;
  border-radius: 5px;
  transition: all 150ms ease-in-out;

  > div {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 35px;
    padding-bottom: 20px;

    &.checked {
      color: ${colors.brand.green};
    }
  }

  img {
    max-width: 65px;
    max-height: 65px;
  }
  .label {
    margin-top: 15px;
    margin-bottom: 25px;
    cursor: pointer;
    justify-content: center;
    font-size: 1rem;
    color: ${colors.text.blue};
  }
  input[type='checkbox'] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    & + label {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      cursor: pointer;

      & + p {
        margin-bottom: 0;
      }

      div {
        margin: auto auto 20px auto;
        height: 17px;
        width: 17px;
        border: 2px solid #bfbfbf;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &.checked {
          border: none;
        }
      }
    }
  }

  &.small {
    height: 100px;

    > div {
      padding: 10px;
    }
  }
`

export default CheckboxTile
export { StyledCheckboxTile }
